import PropTypes from "prop-types";

import {
  useDialogContainer,
  Heading,
  Form,
  Button,
  TextField,
  Dialog,
  Divider,
  Content,
  ButtonGroup,
  Header,
  Text,
  Flex,
  InlineAlert,
} from "@adobe/react-spectrum";

import EmailGear from "@spectrum-icons/workflow/EmailGear";
import Close from "@spectrum-icons/workflow/Close";

import useEmailValidation from "../hooks/useEmailValidation";
import useUpdateEmailToSupabase from "../hooks/useUpdateEmailToSupabase";

import LoremPicsum from "./LoremPicsum";
export default function EditEmailDialog({ sessionEmail }) {
  let dialog = useDialogContainer();
  const { email, isEmailValid, handleEmailChange } = useEmailValidation();
  const { handleUpdateEmailToSupabase, isLoading, getMessage, getAlerte, getVariant } = useUpdateEmailToSupabase();

  return (
    <Dialog size="S">
      <LoremPicsum size={600} id={200} />
      <Heading>
        <Flex alignItems="center" gap="size-100">
          <EmailGear aria-label="Email à envoyer" size="S" color="notice" />
          <Text>Email</Text>
        </Flex>
      </Heading>
      <Header>{sessionEmail}</Header>

      <Divider />
      <Content>
        <Form>
          {getAlerte && (
            <InlineAlert variant={getVariant}>
              <Heading>Information de validation</Heading>
              <Content>{getMessage}</Content>
            </InlineAlert>
          )}

          <TextField
            autoComplete="email"
            inputMode="email"
            label="Modifie ton email"
            name="email"
            type="email"
            validationState={isEmailValid === null ? "" : isEmailValid ? "valid" : "invalid"}
            value={email}
            description="Attends-toi à voir deux fois plus de bonnes nouvelles dans ta boîte de réception ! 🚀✉️"
            onChange={handleEmailChange}
            aria-label="email"
          />
        </Form>
      </Content>

      <ButtonGroup>
        <Button
          variant="secondary"
          style="fill"
          isDisabled={isLoading}
          onPress={dialog.dismiss}
          aria-label="Fermer la fenêtre"
          width="size-600"
        >
          <Close />
        </Button>
        <Button
          isPending={isLoading}
          isDisabled={isLoading || !isEmailValid || email === sessionEmail || getVariant === "positive"}
          aria-label="Modifier"
          variant="primary"
          onPress={() => {
            handleUpdateEmailToSupabase(email);
          }}
        >
          Modifier
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}
EditEmailDialog.propTypes = {
  sessionEmail: PropTypes.string,
};

import { Well, View, Text, Flex, Link, Heading, Image } from "@adobe/react-spectrum";

function FeaturedIn() {
  return (
    <Well>
      <Flex direction="column" justifyContent="center" alignItems="center">
        <Heading marginY="size-50">Ils parlent de nous !</Heading> <Text>Vous avez entendu parler de Tramway ? </Text>
      </Flex>

      <Flex
        marginY="size-250"
        direction="row"
        wrap
        justifyContent="space-around"
        alignItems="center"
        gap="size-100"
        width="100%"
      >
        <View UNSAFE_style={{ backgroundColor: "#333a45" }} padding="size-50" borderRadius="regular">
          <Link
            aria-label="lien vers OPQUAST - France"
            isQuiet
            href="https://www.opquast.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="opquast.svg" height="size-500" width="size-2000" alt="opquast logo" />
          </Link>
        </View>
        <Link
          aria-label="lien vers Ko-fi - Tramway"
          isQuiet
          href="https://ko-fi.com/tramway"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src="kofi_bg_tag_dark.svg" width="size-1600" alt="Ko-fi logo" />
        </Link>
        <View UNSAFE_style={{ backgroundColor: "#333a45" }} padding="size-50" borderRadius="regular">
          {" "}
          <Link
            aria-label="lien vers Twitter - X"
            isQuiet
            href="https://x.com/tramway__RDV"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="x.svg" width="size-400" alt="X logo" />
          </Link>
        </View>
      </Flex>
    </Well>
  );
}

export default FeaturedIn;

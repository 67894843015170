import { View, Avatar, Heading, Flex, Text, ContextualHelp, Content } from "@adobe/react-spectrum";
import { testimonials } from "../../../constants/Testimonials";
import { memo } from "react";

// Styles extraits pour éviter la redondance
const headingStyle = {
  textDecoration: "underline",
  textDecorationStyle: "wavy",
  fontSize: "2rem",
};

// Fonction pour générer l'URL de l'avatar
const getAvatarUrl = (name) => `https://api.dicebear.com/9.x/initials/svg?seed=${name}`;

const Testimonials = memo(function Testimonials() {
  return (
    <>
      <Heading level={2} UNSAFE_style={headingStyle}>
        Histoires
      </Heading>
      <Heading level={3}>
        Derniers témoignages{" "}
        <ContextualHelp variant="info">
          <Heading>Confidentialité et Véracité des Témoignages</Heading>
          <Content>
            <Text>
              Les témoignages que vous soumettez via notre formulaire de contact sont traités de manière strictement
              confidentielle. Vous pouvez à tout moment demander le retrait de votre témoignage ou une anonymisation
              supplémentaire. Par ailleurs, certains témoignages visibles sur notre plateforme sont des personas
              marketing, créés à partir de profils types d&apos;utilisateurs attendus. Ces personas ne représentent pas
              de véritables témoignages, mais sont utilisés uniquement à des fins de communication pour le lancement de
              la plateforme Tramway. Nous nous engageons à ne jamais divulguer vos informations personnelles sans votre
              consentement et à respecter pleinement votre confidentialité conformément à notre politique de protection
              des données.
            </Text>
          </Content>
        </ContextualHelp>
      </Heading>
      <Flex
        direction={{ base: "column", M: "row" }}
        wrap="wrap"
        gap="size-250"
        justifyContent="space-around"
        alignItems="center"
      >
        {testimonials.map((testimonial) => (
          <View
            key={testimonial.name}
            borderWidth="thin"
            borderColor="dark"
            borderEndWidth="medium"
            borderBottomWidth="medium"
            borderBottomStartRadius="large"
            borderBottomEndRadius="large"
            borderTopEndRadius="large"
            borderTopStartRadius="xsmall"
            padding="size-250"
            width={{ base: "100%", M: "size-3000" }}
          >
            <Flex justifyContent="start" alignItems="center" gap="size-100">
              <Avatar
                src={getAvatarUrl(testimonial.name)}
                alt={`Témoignage de ${testimonial.name}`}
                size="avatar-size-700"
              />
              <Text>
                Témoignage de {testimonial.name} - {testimonial.age} ans
              </Text>
            </Flex>

            <Text>{testimonial.message}</Text>
          </View>
        ))}
      </Flex>
    </>
  );
});

export default Testimonials;

import { useNavigate } from "react-router-dom";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";
import { Tooltip, Text, StatusLight, TooltipTrigger, Button, Flex, View } from "@adobe/react-spectrum";
import Heart from "@spectrum-icons/workflow/Heart";

function CTAWithReassurance() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`../${PUBLIC_ROUTES.creerCompte}`);
  };

  // Styles extraits pour éviter de les recalculer à chaque re-rendu
  const unsafeStyle = {
    backgroundColor: "DarkGoldenRod",
  };

  return (
    <>
      <View borderRadius="large" UNSAFE_style={unsafeStyle}>
        <TooltipTrigger>
          <Button
            aria-label="Prendre un Ticket pour s'inscrire"
            variant="primary"
            onPress={handleNavigate}
            staticColor="black"
            style="outline"
          >
            <Heart />
            <Text>Prendre un Ticket</Text>
          </Button>
          <Tooltip>Aller à la page d'inscription</Tooltip>
        </TooltipTrigger>
      </View>

      <Flex gap="size-100" direction="row" alignItems="center" justifyContent="center">
        <StatusLight
          role="status"
          aria-label="Promotion en cours : 2€ = 2 semaines pour les 1000 premiers utilisateurs"
          variant="fuchsia"
        >
          2€ = 2 semaines pour les 1000 premiers utilisateurs
        </StatusLight>
      </Flex>
    </>
  );
}

export default CTAWithReassurance;

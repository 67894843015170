import useSupabaseLogout from "../hooks/useSupabaseLogout";
import { DialogContainer, AlertDialog, Heading, IllustratedMessage, Content } from "@adobe/react-spectrum";
import Engagement from "@spectrum-icons/workflow/Engagement";
import { useState, useContext } from "react";
import { supabase } from "../supabaseClient";
import UserSession from "../contexts/UserSession";

export default function AccountReactivationConfirmationDialog() {
  const [state, setState] = useState({
    error: null,
    isLoading: false,
  });
  const { session, updateSessionIsActive } = useContext(UserSession);
  const { logout } = useSupabaseLogout();

  const alertCancel = async () => await logout();

  const onPrimaryAction = async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const { error } = await supabase
        .from("users_status")
        .update({
          is_active: true,
          updated_at: new Date(),
        })
        .eq("user_id", session?.user?.id);

      if (error) {
        setState({ error: error.message, isLoading: false });
      } else {
        console.log("Timestamp inséré avec succès !");
        setState({ error: null, isLoading: false });
        updateSessionIsActive();
      }
    } catch (err) {
      setState({ error: "Une erreur s'est produite. Veuillez réessayer.", isLoading: false });
    }
  };

  return (
    <DialogContainer type="fullscreenTakeover">
      <AlertDialog
        autoFocusButton="primary"
        title={state.error ? "Vous avez déjà activé votre compte." : "Reactive ton compte Tramway !"}
        variant={state.error ? "error" : "confirmation"}
        primaryActionLabel={state.isLoading ? "Chargement..." : state.error ? "Essayer à nouveau" : "Confirmer"}
        onPrimaryAction={onPrimaryAction}
        cancelLabel="Quitter"
        onCancel={alertCancel}
      >
        <IllustratedMessage>
          <Engagement size="M" />
          <Heading>Active ton compte à nouveau</Heading>
          <Content>
            {state.error
              ? state.error
              : "Active ton compte à nouveau 🚋 Youpi ! Tu es sur le point de redonner vie à ton compte Tramway. En compostant ton ticket, tu réactiveras toutes les fonctionnalités géniales. Sinon, ton compte sera comme un tramway arrivé au Terminus, prêt à rentrer à l&apos;entrepôt."}
          </Content>
        </IllustratedMessage>
      </AlertDialog>
    </DialogContainer>
  );
}

import { Suspense, useContext } from "react";
import { Routes, Route, useLocation, matchPath } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import Nav from "./components/Nav";
import BottomNav from "./components/BottomNav";
import Loading from "./components/Loading";
import ErrorFallback from "./components/ErrorFallback";
import ProtectedRoute from "./components/ProtectedRoute";

import GetPublicRoutes from "./config/GetPublicRoutes";
import GetPrivateRoutes from "./config/GetPrivateRoutes";

import UserSession from "./contexts/UserSession";

import { ToastContainer } from "@react-spectrum/toast";
import { PUBLIC_ROUTES } from "./constants/publicroutes";
import { PRIVATE_ROUTES } from "./constants/privateroutes";

function App() {
  const location = useLocation();
  const privateRoutes = GetPrivateRoutes();
  const publicRoutes = GetPublicRoutes();
  const { sessionRole, currentLevelMFA, nextLevelMFA, sessionIsActive, sessionTerms } = useContext(UserSession);

  const isIndex = matchPath({ path: PUBLIC_ROUTES.index }, location.pathname);

  return (
    <>
      {!isIndex && <Nav />}

      <ErrorBoundary fallbackRender={ErrorFallback}>
        <Suspense fallback={<Loading />}>
          <Routes>
            {/* PUBLIC ROUTES */}
            {publicRoutes.map((createPublicRoute) => (
              <Route key={createPublicRoute.path} path={createPublicRoute.path} element={createPublicRoute.element} />
            ))}

            {/* PRIVATE ROUTES */}
            <Route
              path={PRIVATE_ROUTES.private}
              element={
                <ProtectedRoute
                  sessionIsActive={sessionIsActive}
                  isAuthenticated={sessionRole}
                  hasSignedTerms={sessionTerms}
                  currentLevelMFA={currentLevelMFA}
                  nextLevelMFA={nextLevelMFA}
                />
              }
            >
              <Route path={PRIVATE_ROUTES.accueil} element={privateRoutes.accueilLazy} />
              <Route path={PRIVATE_ROUTES.cercles} element={privateRoutes.circlesLazy} />
              <Route path={PRIVATE_ROUTES.flash} element={privateRoutes.flashLazy} />
              <Route path={PRIVATE_ROUTES.messages} element={privateRoutes.messagesLazy} />
              <Route path={PRIVATE_ROUTES.profile} element={privateRoutes.profileLazy} />
              <Route path={PRIVATE_ROUTES.public_profile} element={privateRoutes.publicProfileLazy} />
              <Route path={PRIVATE_ROUTES.deconnexion} element={privateRoutes.deconnexionLazy} />
              <Route path={PRIVATE_ROUTES.compte} element={privateRoutes.compteLazy} />
            </Route>
          </Routes>
        </Suspense>
      </ErrorBoundary>

      <ToastContainer aria-label="Notifications" />
      {!isIndex && <BottomNav />}
    </>
  );
}

export default App;

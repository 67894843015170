import { Heading, Content, IllustratedMessage } from "@adobe/react-spectrum";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import Chat from "@spectrum-icons/workflow/Chat";

export default function Messages() {
  return (
    <PrivateLayout>
      <IllustratedMessage maxWidth="100%" margin="size-400">
        <Chat size="L" aria-label="Messagerie du Tramway" />
        <Heading>Il n&apos;y a pas encore de courrier.</Heading>
        <Content>
          Tramway • courant alternatif <span title="Emoji : Marque déposée"></span>®
        </Content>
      </IllustratedMessage>
    </PrivateLayout>
  );
}

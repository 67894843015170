import PropTypes from "prop-types";
import useVibration from "../hooks/useVibration";
import useSupabaseLogout from "../hooks/useSupabaseLogout";

import { Text, Button } from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import KeyClock from "@spectrum-icons/workflow/KeyClock";
import Refresh from "@spectrum-icons/workflow/Refresh";

const UnenrollMFAButton = ({ isLoading, validationState, getLastFactorId, unenRollMFA }) => {
  const { logout } = useSupabaseLogout();
  const { vibrate } = useVibration();

  const handleUnenrollMFA = async () => {
    try {
      await getLastFactorId();
      await unenRollMFA();
      vibrate();
      ToastQueue.neutral("MFA inactive", { timeout: 5000 });
      logout("local");
    } catch (error) {
      console.log(error);
      ToastQueue.negative("Oops ! Réessaye. Si le problème persiste, contacte-nous pour une assistance.", {
        timeout: 5000,
      });
    }
  };

  const renderValidationToast = () => {
    if (validationState) {
      ToastQueue.info(validationState?.message, {
        timeout: 5000,
      });
    }
  };

  return (
    <Button
      width="100%"
      height="size-700"
      isDisabled={isLoading}
      isPending={isLoading}
      variant="primary"
      onPress={handleUnenrollMFA}
      aria-label="Désactiver la double authentification MFA"
    >
      {renderValidationToast()}
      {validationState ? <Refresh color="notice" /> : <KeyClock color="notice" />}
      {validationState ? (
        <Text>Oops ! Réessaye</Text>
      ) : (
        <Text>
          Désactiver la <span title="MFA : Double authentification">Double authentification</span>
        </Text>
      )}
    </Button>
  );
};

UnenrollMFAButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  validationState: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  getLastFactorId: PropTypes.func.isRequired,
  unenRollMFA: PropTypes.func.isRequired,
};

export default UnenrollMFAButton;

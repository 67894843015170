const SPECTRUM_LIGHT_COLOR_VALUES = [
  "gray-50",
  "gray-75",
  "gray-100",
  "gray-200",
  "gray-300",
  "gray-400",
  "gray-500",
  "gray-600",
  "gray-700",
  "gray-800",
  "gray-900",
  "red-100",
  "red-200",
  "red-300",
  "red-400",
  "red-500",
  "red-600",
  "red-700",
  "red-800",
  "red-900",
  "red-1000",
  "red-1100",
  "red-1200",
  "red-1300",
  "red-1400",
  "orange-100",
  "orange-200",
  "orange-300",
  "orange-400",
  "orange-500",
];

export { SPECTRUM_LIGHT_COLOR_VALUES };

import PropTypes from "prop-types";
import { Flex, Badge, Heading, Text, View } from "@adobe/react-spectrum";

import Ribbon from "@spectrum-icons/workflow/Ribbon";
function ValueProposition({ isSmallScreen }) {
  return (
    <>
      <Flex
        direction="row"
        wrap
        gap="size-100"
        alignItems={{ base: "center", S: "center", M: "center", L: "start" }}
        justifyContent="start"
      >
        <Badge variant="neutral">
          <Ribbon /> <Text>Ecoconçu</Text>
        </Badge>{" "}
        <Badge variant="neutral">
          <Ribbon /> <Text>Accessible</Text>
        </Badge>
      </Flex>

      <Heading
        alignSelf="center"
        justifySelf="center"
        marginTop="size-100"
        marginBottom="size-250"
        level={1}
        UNSAFE_style={{
          textAlign: isSmallScreen ? "center" : "left",
          fontSize: "2rem",
        }}
      >
        L&apos;app de rencontre
        <br /> la plus Roots, trouve ton{" "}
        <View
          UNSAFE_style={{
            position: "relative",
            display: "inline-block",
            fontSize: "2rem",
            fontFamily: "Nunito ExtraBold",
          }}
        >
          <Text
            UNSAFE_style={{
              position: "relative",
              zIndex: 1,
              textDecoration: "underline",
              textDecorationStyle: "wavy",
              fontSize: "2rem",
              fontFamily: "Nunito ExtraBold",
            }}
          >
            Coeur d&apos;artichaut...
          </Text>
          <View
            UNSAFE_style={{
              content: "''",
              position: "absolute",
              top: "-1px",
              left: "-1px",
              height: "90%",
              width: "100%",
              backgroundColor: "#788128",
              transform: "rotate(2deg)",
              borderRadius: "20% 25% 20% 24%",
              zIndex: 0,
              padding: "10px 3px 3px 10px",
              marginLeft: "-3px",
              marginRight: "-3px",
            }}
          ></View>
        </View>
      </Heading>
    </>
  );
}

ValueProposition.displayName = "ValueProposition";

ValueProposition.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
};

export default ValueProposition;

import { Text } from "@adobe/react-spectrum";
import PropTypes from "prop-types";

function Subtitle({ isSmallScreen }) {
  return (
    <Text
      UNSAFE_style={{
        textAlign: isSmallScreen ? "center" : "left",
      }}
    >
      Tous les Célibataires : une magnifique opportunité de trouver l’Amour{" "}
      <span lang="en" title="bio et jolie à la fois">
        Biotiful
      </span>{" "}
      et l’amitié{" "}
      <span lang="en" title="verte attitude">
        Supergreen
      </span>
      . <span>Handi & PMR friendly</span> <span title="emoji :main en forme de coeur">🫶</span>
    </Text>
  );
}

Subtitle.displayName = "Subtitle";

Subtitle.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
};

export default Subtitle;

import { useState, useCallback, useEffect } from "react";
import { supabase } from "../supabaseClient";

const useAuthenticatorAssuranceLevel = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authLevel, setAuthLevel] = useState(null);

  const getAuthenticatorAssuranceLevel = useCallback(async () => {
    try {
      const { data: dataAssuranceLevel, error: errorAssuranceLevel } =
        await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

      if (errorAssuranceLevel) {
        setError(true);
      } else {
        setAuthLevel(dataAssuranceLevel?.nextLevel);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAuthenticatorAssuranceLevel();
  }, [getAuthenticatorAssuranceLevel]);

  return { authLevel, error, loading };
};

export default useAuthenticatorAssuranceLevel;

import { lazy, Suspense, memo, useContext } from "react";
import UserSession from "../../contexts/UserSession";
// import { useCircles } from "../Circles/circlesHooks/useCircles";
import Loading from "../Loading";
const LazyUserProfilDetails = memo(lazy(() => import("./ProfilDetails")));
const LazyProfilPictures = memo(lazy(() => import("../MemberMedia/ProfilPictures")));
import AgePicker from "./AgePicker";
import LoginAndSecurity from "../../components/LoginAndSecurity";

import {
  Tabs,
  Item,
  Well,
  Divider,
  View,
  TabPanels,
  TabList,
  Text,
  ContextualHelp,
  Content,
  Heading,
} from "@adobe/react-spectrum";

import Photo from "@spectrum-icons/workflow/ImageAdd";
import Details from "@spectrum-icons/workflow/NewsAdd";
import Date from "@spectrum-icons/workflow/IdentityService";

function HomeTabs() {
  const { session } = useContext(UserSession);
  const userId = session?.user?.id;
  // const { circles, isLoading, isError, subscribedCircles } = useCircles(userId);
  // console.log(userId, subscribedCircles, circles);

  return (
    <>
      <Tabs
        alignSelf="center"
        justifySelf="center"
        disallowEmptySelection="true"
        defaultSelectedKey="1"
        density="regular"
        aria-label="Les membres du Tramway sont tous ici"
        // disabledKeys={[ "2", "3"]}
      >
        <TabList>
          <Item key="1" textValue="ID">
            <Date size="S" aria-label="ID" />
            <Text>ID</Text>
          </Item>
          <Item key="2" textValue="Photos">
            <Photo size="S" aria-label="Photos" />
            <Text>Photos</Text>
          </Item>

          <Item key="3" textValue="Profil">
            <Details size="S" aria-label="Profil" />
            <Text>Profil</Text>
          </Item>
        </TabList>

        <TabPanels marginTop="size-200" marginX="size-0">
          <Item key="1" textValue="Apporte une Bio attrayante à ton profil.">
            Rensiegne ton pseudo et ta date de naissance, cette app est reservé au personne majeurs.
            <Heading lang="en" margin="size-250" level={2}>
              Mon anniversaire
            </Heading>
            <View borderWidth="thin" borderColor="dark" borderRadius="medium" padding="size-250">
              <AgePicker />
              <Divider marginY="size-250" />
              <LoginAndSecurity />
            </View>
          </Item>

          <Item key="2" textValue="Modifier ma gallerie">
            Modifier ma gallerie
            <ContextualHelp variant="info">
              <Heading>Gallerie</Heading>
              <Content>
                <Text>Ajouter des photos depuis mon appareil au format JPG, PNG ou WEBP.</Text>
              </Content>
            </ContextualHelp>
            <LazyProfilPictures userId={userId} />
          </Item>

          <Item key="3" textValue="Ce qui te rends unique.">
            Ce qui te rends unique. <span title="emoji : couronne, étincelles">👑✨</span>
            <Suspense fallback={<Loading />}>
              <LazyUserProfilDetails userId={userId} />
            </Suspense>
          </Item>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default HomeTabs;

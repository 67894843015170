import { Flex, View } from "@adobe/react-spectrum";
import PropTypes from "prop-types";
import ValueProposition from "./ValueProposition";
import VisualExemples from "./VisualExamples";
import CTAWithReassurance from "./CTAWithReassurance";
import Subtitle from "./Subtitle";
import SocialProof from "./SocialProof";
import { memo } from "react";

const HeroSection = memo(function HeroSection({ isSmallScreen }) {
  return (
    <View maxWidth="100%" paddingStart="size-500" paddingEnd="size-500" paddingTop="size-100" paddingBottom="size-500">
      <Flex
        width="100%"
        gap="size-100"
        direction={isSmallScreen ? "column" : "row"}
        alignItems={isSmallScreen ? "center" : "start"}
        justifyContent={isSmallScreen ? "center" : "space-between"}
      >
        {/* Section Textuelle */}
        <View maxWidth={isSmallScreen ? "100%" : "55%"}>
          <Flex
            direction="column"
            gap="size-100"
            alignItems={isSmallScreen ? "center" : "start"}
            justifyContent={isSmallScreen ? "center" : "start"}
          >
            <ValueProposition isSmallScreen={isSmallScreen} />
            <Subtitle isSmallScreen={isSmallScreen} />
            <CTAWithReassurance />
            <SocialProof />
          </Flex>
        </View>

        {/* Section Visuelle */}
        <VisualExemples />
      </Flex>
    </View>
  );
});

HeroSection.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
};

export default HeroSection;

import { View, Heading } from "@adobe/react-spectrum";
import ThirdCTA from "./ThirdCTA";
import SecondCTA from "./SecondCTA";
import Actuality from "./Actuality";
import Contact from "./Contact";
import Faq from "./Faq";
import Testimonials from "./Testimonials";
import { memo } from "react";

// Styles extraits pour éviter de les recalculer à chaque re-rendu
const unsafeStyle = {
  textDecoration: "underline",
  textDecorationStyle: "wavy",
  fontSize: "2rem",
};

const MoreSocialProof = memo(function MoreSocialProof() {
  return (
    <View paddingStart="size-500" paddingEnd="size-500" paddingTop="size-500" paddingBottom="size-500">
      <Heading UNSAFE_style={unsafeStyle} level={2}>
        Holistic Trip
      </Heading>
      <Heading level={3}>Tramway _ Le point de départ de l&apos;aventure à deux</Heading>

      <SecondCTA />
      <Faq />
      <ThirdCTA />
      <Contact />
      <Testimonials />
      <Actuality />
    </View>
  );
});

export default MoreSocialProof;

import Tips from "./Tips";
import Reviews from "./Reviews";
import { Flex, View, Avatar, Slider } from "@adobe/react-spectrum";
import { SPECTRUM_LIGHT_COLOR_VALUES } from "../constants/spectrumLightColors";
import useReviews from "../hooks/useReviews";

function WallOfFame() {
  const { averageRate, error } = useReviews();

  return (
    <>
      <View
        position="absolute"
        top={{ base: "60%", L: "size-4000" }}
        borderWidth="thin"
        borderColor="dark"
        borderRadius="medium"
        padding="size-250"
        backgroundColor="gray-100"
      >
        <Flex direction="column" gap="size-100">
          <Slider
            trackGradient={["white", "#ff9900"]}
            width="100%"
            minValue={1}
            step={0.1}
            maxValue={5}
            label="Vos évaluations"
            value={error ? 4.5 : (averageRate ?? 4.5)}
            getValueLabel={(x) => `${x} / 5`}
          />
          <Reviews />
          <Tips />
        </Flex>
      </View>

      <Flex justifyContent="center" alignItems="center" direction="row" gap="size-400" wrap>
        {SPECTRUM_LIGHT_COLOR_VALUES.map((color) => (
          <View borderRadius="regular" key={color} colorVersion={6} width="size-800" height="size-800">
            <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
              <Avatar
                size="avatar-size-700"
                src={`https://api.dicebear.com/8.x/avataaars/svg?seed=${color}&clothingGraphic=skullOutline,resist,pizza,skull,hola,diamond,deer,cumbia,bear,bat&eyebrows=defaultNatural,default,flatNatural,frownNatural,raisedExcited,upDownNatural,upDown&eyes=default,eyeRoll,happy,hearts,side,squint,surprised,wink,winkWacky,xDizzy&mouth=smile,tongue,twinkle,eating,default,concerned`}
                alt="default Adobe avatar"
              />
            </Flex>
          </View>
        ))}
      </Flex>
    </>
  );
}

export default WallOfFame;

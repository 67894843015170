import { useReducer, useEffect, useContext, useCallback, useMemo } from "react";
import NotificationList from "./NotificationList";
import useVibration from "../hooks/useVibration";
import UserSession from "../contexts/UserSession";
import { supabase } from "../supabaseClient";
import {
  Flex,
  ActionButton,
  DialogTrigger,
  Dialog,
  Heading,
  Divider,
  Content,
  Tooltip,
  TooltipTrigger,
  View,
  Text,
  StatusLight,
  IllustratedMessage,
  Footer,
  Button,
} from "@adobe/react-spectrum";
import Bell from "@spectrum-icons/workflow/Bell";
import PushNotification from "@spectrum-icons/workflow/PushNotification";

const notificationReducer = (state, action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return { ...state, notifications: action.payload };
    case "SET_PERMISSION":
      return { ...state, isGranted: action.payload };
    case "ADD_NOTIFICATION":
      return { ...state, notifications: { ...state.notifications, ...action.payload } };
    default:
      return state;
  }
};

function Notifications() {
  const { vibrate } = useVibration();
  const { session } = useContext(UserSession);
  const [state, dispatch] = useReducer(notificationReducer, {
    notifications: {},
    isGranted: false,
  });

  const { notifications, isGranted } = state;
  const { flash_count = 0, message_count = 0, visit_count = 0 } = notifications;
  const hasNotification = message_count > 0 || visit_count > 0 || flash_count > 0;

  // Mémoriser les données des notifications pour éviter la boucle
  const notificationsData = useMemo(
    () => [
      {
        id: 1,
        name: "Flash",
        count: flash_count,
        title: "Quelqu'un t'a envoyé un flash",
        body: `${flash_count} Flash${flash_count > 1 ? "s" : ""}`,
      },
      {
        id: 2,
        name: "Message",
        count: message_count,
        title: "Tu as du courrier",
        body: `${message_count} Message${message_count > 1 ? "s" : ""}`,
      },
      {
        id: 3,
        name: "Visite",
        count: visit_count,
        title: "Tu as de la visite",
        body: `${visit_count} Visite${visit_count > 1 ? "s" : ""}`,
      },
    ],
    [flash_count, message_count, visit_count],
  );

  // Fonction pour marquer toutes les notifications comme lues
  const markAllAsRead = useCallback(async () => {
    const userId = session?.user?.id;
    if (userId) {
      const { error } = await supabase.rpc("mark_notification_as_read", { p_user_id: userId });
      if (error) {
        console.error("Erreur lors de la mise à jour des notifications:", error);
      }
    }
  }, [session?.user?.id]);

  // Demander la permission de notification
  const askUserPermission = async () => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      const permission = await Notification.requestPermission();
      dispatch({ type: "SET_PERMISSION", payload: permission === "granted" });
    }
  };

  // Récupérer les notifications depuis Supabase
  const fetchNotificationCounts = useCallback(async () => {
    const userId = session?.user?.id;
    if (userId) {
      const { data, error } = await supabase.rpc("get_notification_counts", { p_user_id: userId });
      if (error) {
        console.error("Erreur lors de la récupération des compteurs de notifications:", error);
      } else {
        dispatch({ type: "SET_NOTIFICATIONS", payload: data || {} });
      }
    }
  }, [session?.user?.id]);

  const showNativeNotification = useCallback(
    (notificationsData) => {
      notificationsData.forEach(({ count, title, body }) => {
        if (isGranted && count > 0) {
          new Notification(title, { body });
        }
      });
    },
    [isGranted],
  );

  useEffect(() => {
    askUserPermission();
    fetchNotificationCounts();

    // Abonnement aux notifications en temps réel
    const supabaseNotifications = supabase
      .channel("notifications")
      .on("postgres_changes", { event: "*", schema: "public", table: "notifications" }, (payload) => {
        const newNotification = payload.new;
        dispatch({ type: "ADD_NOTIFICATION", payload: newNotification });
      })
      .subscribe();

    showNativeNotification(notificationsData);
    vibrate();

    return () => {
      supabaseNotifications.unsubscribe();
    };
  }, [isGranted, notificationsData, session?.user?.id]);

  return (
    <DialogTrigger type="tray">
      <TooltipTrigger>
        <View borderRadius="regular">
          <ActionButton isQuiet aria-label="notifications">
            <Bell />
            {hasNotification && !notifications?.is_read && (
              <StatusLight aria-label="Vous avez des nouvelles notifications" variant="positive" role="status" />
            )}
          </ActionButton>
        </View>
        <Tooltip>Notifications</Tooltip>
      </TooltipTrigger>

      <Dialog>
        <Heading>Notifications</Heading>
        <Divider />
        <Content>
          <Flex direction="column" gap={8}>
            {isGranted ? (
              hasNotification && !notifications?.is_read ? (
                <NotificationList notificationsData={notificationsData} markAllAsRead={markAllAsRead} />
              ) : (
                <IllustratedMessage marginY="size-250">
                  <PushNotification size="M" />
                  <Heading>Aucune notification</Heading>
                  <Content>Aucune nouvelle notification pour l'instant</Content>
                </IllustratedMessage>
              )
            ) : (
              <IllustratedMessage marginY="size-250">
                <PushNotification size="M" />
                <Heading>Désactivées</Heading>
                <Content>Paramètres : activez les notifications</Content>
              </IllustratedMessage>
            )}
          </Flex>
        </Content>
        {hasNotification && !notifications?.is_read && (
          <Footer>
            <Flex width="100%" justifyContent="right" alignItems="end" gap="size-100">
              <Button marginY="size-250" variant="primary" onPress={markAllAsRead}>
                <Text>Marquer comme lu</Text>
              </Button>
            </Flex>
          </Footer>
        )}
      </Dialog>
    </DialogTrigger>
  );
}

export default Notifications;

import { Image, Button, Flex, Text } from "@adobe/react-spectrum";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";
import { useNavigate } from "react-router-dom";
import Heart from "@spectrum-icons/workflow/Heart";
import { memo } from "react";

// Styles extraits pour éviter les répétitions
const imageStyles = {
  marginY: "size-250",
  height: "size-3000",
  width: "size-3000",
  objectFit: "cover",
};

const SecondCTA = memo(function SecondCTA() {
  const navigate = useNavigate();

  return (
    <Flex direction="column" gap="size-100" alignItems="center" justifyContent="center">
      <Image
        {...imageStyles}
        src="alternative_attitude.png"
        alt="Un couple amoureux allongé dans l'herbe, devant leur tente, observe la montagne à l'aube. La scène romantique est baignée de la lumière douce du soleil levant, capturant un moment intime en pleine nature."
      />

      <Button variant="primary" onPress={() => navigate(PUBLIC_ROUTES.creerCompte)}>
        <Heart color="negative" />
        <Text> XOXO _ C&apos;est parti ! </Text>
      </Button>
    </Flex>
  );
});

export default SecondCTA;

import { useState, lazy, Suspense } from "react";

import Loading from "./Loading";
const TipsDialog = lazy(() => import("./TipsDialog"));

import { Button, DialogContainer } from "@adobe/react-spectrum";

export default function Tips() {
  let [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button variant="primary" aria-label="Faire un don" style="outline" onPress={() => setOpen(true)}>
        Donner
      </Button>

      <DialogContainer type="modal" isDismissable onDismiss={() => setOpen(false)}>
        {isOpen && (
          <Suspense fallback={<Loading />}>
            <TipsDialog />
          </Suspense>
        )}
      </DialogContainer>
    </>
  );
}

import { Image, Flex, View } from "@adobe/react-spectrum";
// import useUnsplashPhoto from "../Hooks/useUnsplashPhoto";

function VisualExamples() {
  // const { photoUrl, portfolioUrl, name, altDescription } = useUnsplashPhoto("XfGHiAmDkJo", "small");

  let photoUrl = "alternative_attitude.png";
  let altDescription =
    "Un couple amoureux allongé dans l'herbe, devant leur tente, observe la montagne à l'aube. La scène romantique est baignée de la lumière douce du soleil levant, capturant un moment intime en pleine nature.";

  return (
    <View maxWidth={{ base: "100%", S: "100%", M: "100%", L: "40%" }} justifyContent="center">
      <Flex direction="column" gap="size-100" alignItems="center" justifyContent="center">
        <Image
          loading="lazy"
          width={{ base: "80%", S: "100%", M: "80%", L: "100%" }}
          alt={altDescription ?? ""}
          // src={photoUrl}
          src={photoUrl ?? "alternative_attitude.png"}
          objectFit="cover"
        />

        <span
          style={{
            fontFamily: "monospace",
            color: "DarkGoldenRod",
            fontWeight: "bold",
          }}
          title="attitude alternative"
          lang="en"
        >
          Alternative <br />
          with attitude <span title="emoji : un coeur de cupidon et une feuille d'olivier">💘 🌿</span>
        </span>
      </Flex>
    </View>
  );
}

export default VisualExamples;

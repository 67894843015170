import { useContext, useMemo, useEffect } from "react";
import { ButtonGroup } from "@adobe/react-spectrum";
import UserSession from "../contexts/UserSession";
import useAuthenticatorAssuranceLevel from "../hooks/useAuthenticatorAssuranceLevel";
import UserChangeEmailButton from "./UserChangeEmailButton";
import UserMultiFactorAuthButton from "./UserMultiFactorAuthButton";
import UserDeleteAccountButton from "./UserDeleteAccountButton";
import UserDownloadPersonalDataButton from "./UserDownloadPersonalDataButton";

export default function LoginAndSecurity() {
  const { setNextLevelMFA, nextLevelMFA, session, sessionEmail } = useContext(UserSession);
  const { authLevel, error, loading } = useAuthenticatorAssuranceLevel();

  useEffect(() => {
    if (authLevel && authLevel !== nextLevelMFA) {
      setNextLevelMFA(authLevel);
    }
  }, [authLevel, nextLevelMFA, setNextLevelMFA]);

  const isMFA = useMemo(() => nextLevelMFA === "aal2", [nextLevelMFA]);

  return (
    <ButtonGroup width="size-2400" orientation="vertical" align="start" isDisabled={!session}>
      <UserChangeEmailButton sessionEmail={sessionEmail} />
      {!error && !loading && <UserMultiFactorAuthButton isMFA={isMFA} />}
      <UserDownloadPersonalDataButton />
      <UserDeleteAccountButton />
    </ButtonGroup>
  );
}

import { useNavigate } from "react-router-dom";

import Login from "@spectrum-icons/workflow/Login";
import { View, TooltipTrigger, ActionButton, Tooltip } from "@adobe/react-spectrum";

import { PUBLIC_ROUTES } from "../constants/publicroutes";

function LoginBtn() {
  const nav = useNavigate();
  return (
    <TooltipTrigger>
      <View
        UNSAFE_style={{
          backgroundColor: "DarkGoldenRod",
        }}
        borderRadius="regular"
      >
        <ActionButton isQuiet aria-label="Connexion" onPress={() => nav("./" + PUBLIC_ROUTES.connexion)}>
          <Login aria-label="notifications" />
        </ActionButton>
      </View>
      <Tooltip>Connexion</Tooltip>
    </TooltipTrigger>
  );
}

export default LoginBtn;

import PropTypes from "prop-types";
import { useMemo, lazy, memo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PUBLIC_ROUTES } from "../constants/publicroutes";

import SignTerms from "./SignTerms";
import MFAHOC from "./MFAHOC";
import AccountReactivationConfirmationDialog from "./AccountReactivationConfirmationDialog";

const SetlazyMFAForm = memo(lazy(() => import("./FormMFA")));
const MFAHOCWrapper = MFAHOC(SetlazyMFAForm);

export default function ProtectedRoute({
  isAuthenticated,
  hasSignedTerms,
  currentLevelMFA,
  nextLevelMFA,
  sessionIsActive,
}) {
  const isMFACheckRequired = useMemo(
    () => nextLevelMFA === "aal2" && nextLevelMFA !== currentLevelMFA,
    [nextLevelMFA, currentLevelMFA],
  );

  if (isAuthenticated !== "authenticated") {
    return <Navigate to={`../${PUBLIC_ROUTES.connexion}`} replace />;
  }

  if (!sessionIsActive) {
    return <AccountReactivationConfirmationDialog />;
  }

  if (!hasSignedTerms) {
    return <SignTerms />;
  }

  if (isMFACheckRequired) {
    return <MFAHOCWrapper />;
  }

  return <Outlet />;
}

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.string.isRequired,
  hasSignedTerms: PropTypes.string,
  currentLevelMFA: PropTypes.string,
  nextLevelMFA: PropTypes.string,
  sessionIsActive: PropTypes.bool.isRequired,
};

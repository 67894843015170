import { useState } from "react";
import emailjs from "@emailjs/browser";
import useSecurityEmailVerification from "../hooks/useSecurityEmailVerification";

const emailJs_PUBLIC_KEY = import.meta.env.VITE_EMAILJS_PUBLIC_KEY;
emailjs.init(emailJs_PUBLIC_KEY);

const useEmailSender = () => {
  const { securityEmailVerification } = useSecurityEmailVerification();
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [variant, setVariant] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async (e, emailToCheck) => {
    e.preventDefault();
    e.stopPropagation();
    let data = e.currentTarget;
    setShowMessage(false);
    const emailJs_SERVICE_ID = import.meta.env.VITE_EMAILJS_SERVICE_ID;
    const emailJs_TEMPLATE_ID = import.meta.env.VITE_EMAILJS_TEMPLATE_ID;

    if (!emailJs_SERVICE_ID || !emailJs_TEMPLATE_ID) {
      setShowMessage(true);
      setVariant("notice");
      setMessage(`Oups... Nous faisons notre maximum pour résoudre le problème rapidement.`);
      setIsLoading(false);
      return;
    }

    if (emailToCheck) {
      const isEmailValid = await securityEmailVerification(emailToCheck);
      if (isEmailValid === true) {
        setVariant("positive");
        setShowMessage(true);
        setMessage("Veuillez patienter...");
        setIsLoading(true);

        emailjs.sendForm(emailJs_SERVICE_ID, emailJs_TEMPLATE_ID, data).then(
          (result) => {
            setShowMessage(true);
            setVariant("positive");
            setMessage(result.status === 200 && "Reçu 5 sur 5! Tout va bien.✨");
            setIsLoading(false);
          },
          (error) => {
            setShowMessage(true);
            setVariant("notice");
            setMessage(`Échec de l'envoi de ton message. Message non reçu. Statut : ${error.text}`);
            setIsLoading(false);
          },
        );
      } else {
        setVariant("notice");
        setShowMessage(true);
        setMessage(
          "On nous dit dans l'oreillette que cette adresse email n'est pas la plus sûr pour Tramway et ses utilisateurs.",
        );
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);

      emailjs.sendForm(emailJs_SERVICE_ID, emailJs_TEMPLATE_ID, data).then(
        (result) => {
          setShowMessage(true);
          setVariant("positive");
          setMessage(result.status === 200 && "Reçu 5 sur 5! Tout va bien.✨");
          setIsLoading(false);
        },
        (error) => {
          setShowMessage(true);
          setVariant("notice");
          setMessage(`Échec de l'envoi de ton message. Message non reçu. Statut : ${error.text}`);
          setIsLoading(false);
        },
      );
    }
  };

  return {
    sendEmail,
    isLoading,
    showMessage,
    setShowMessage,
    variant,
    message,
  };
};

export default useEmailSender;
